.buttonMessage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--Primary);
    border-radius: 25px;
    padding: 10px 20px;
    color: var(--White);
    cursor: pointer;
    margin: 5px;
    border: 1px solid var(--Primary);
}
.buttonMessage svg {
    margin-right: 10px;
}
.buttonMessage:hover {
    background-color: var(--White);
    color: var(--Primary);
    border: 1px solid var(--Primary);
}

.content-modal-Message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 8px;
    background-color: var(--White);
}
.content-modal-Message .itensModalMessage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    overflow:auto; 
    max-height: 500px;
    margin: 20px 10px;
    padding: 20px;
}

.content-modal-Message .itensModalMessage svg {
    color: var(--Primary);
    font-size: 45px;
}
.content-modal-Message .itensModalMessage h1 {
    font-weight: 800;
    color: var(--Paragraph);
    margin: 15px 0;
}
.content-modal-Message .itensModalMessage h4 {
    margin-right: 30px;
}

.content-modal-Message .itensModalMessage a {
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    background-color: var(--Primary);
    color: var(--White);
    margin: 30px 0 10px 0px;
    font-weight: 700;
    text-decoration: none;
    font-size: 14px;
}

