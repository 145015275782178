.About {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.About .topImageAbout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    height: 500px;
    background-image: url("../../assets/images/background7.jpg"); /* The image used */
    background-color: #cccccc; /* Used if the image is unavailable */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    color: var(--White);
}

.About .topImageAbout .back {
    padding: 100px 200px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    background-image: linear-gradient(rgba(0,0,0, 0.01), rgba(0,0,0, 0.8));
}

.About .topImageAbout .back h1 {
    font-size: 42px;
    color: var(--White);
    text-align: left;
    font-weight: 700;
}
.About .topImageAbout .back h3 {
    margin: 10px 0;
}
.About .aboutInfos {
    width: 100%;
    height: 100%x;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin: 40px 0;
}
.About .aboutInfos .ImagesAbout {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.About .aboutInfos .ImagesAbout .imageAbout1 {
    width: 350px;
    height: 200px;
    border-radius: 0 20px 0 20px;
    object-fit: cover;
    margin: 5px;
}
.About .aboutInfos .ImagesAbout .imageAbout1 img {
    width: 350px;
    height: 200px;
    border-radius: 0 20px 0 20px;
    object-fit: cover;
}
.About .aboutInfos .ImagesAbout .imageAbout2 {
    width: 350px;
    height: 200px;
    border-radius: 0 20px 0 20px;
    object-fit: cover;
    margin: 5px;
}
.About .aboutInfos .ImagesAbout .imageAbout2 img {
    width: 350px;
    height: 200px;
    border-radius: 0 20px 0 20px;
    object-fit: cover;
}

.About .aboutInfos .TextAboutInfo {
    width: 50%;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 20px;
    white-space: pre-line;
}
.About .aboutInfos .TextAboutInfo h2 {
    color: var(--Primary);
    font-weight: 700;
    margin: 5px 0;
}
.About .aboutInfos .TextAboutInfo .BlockInfosAbout {
    width: 100%;
    margin: 10px 5px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.About .aboutInfos .TextAboutInfo .BlockInfosAbout .svgInfo {
    width: 70px;
    height: 60px;
    margin: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--PrimaryCharacteristcs);
    border-radius: 50%;
}

.About .aboutInfos .TextAboutInfo .BlockInfosAbout .svgInfo svg {
    color: var(--Primary);
    font-size: 24px;
}

.About .aboutInfos .TextAboutInfo .BlockInfosAbout .BlockInfosText {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.About .aboutInfos .TextAboutInfo .BlockInfosAbout .BlockInfosText h4 {
    color: var(--Paragraph);
    font-weight: 700;
}

.About .textTeam {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.About .textTeam h2 {
    color: var(--Primary);
    font-weight: 700;
}


.About .team {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.About .team .teamUnic {
    width: 200px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.About .team .teamUnic .teamImage {
    width: 200px;
    height: 250px;
    object-fit: cover;
    border-radius: 6px;
}
.About .team .teamUnic .teamImage img {
    width: 200px;
    height: 250px;
    object-fit: cover;
    border-radius: 6px;
}

.About .team .teamUnic .teamInfos {
    width: 80%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--White);
    margin-top: -30px;
    border-radius: 0 20px 0 20px;
    box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.3);
    -webkit-box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.3);
}

.About .team .teamUnic .teamInfos h5 {
    color: var(--Gray);
}

.About .team .teamUnic .teamInfos h4 {
    color: var(--Paragraph);
    font-weight: 700;
}


@media (max-width: 1050px) {
    .About .topImageAbout .back {
        padding: 50px 100px;
    }
   
    @media (max-width: 950px) {

        .About .aboutInfos {
            height: 100%;
        }

    @media (max-width: 850px) {

        .About .aboutInfos {
            width: 100%;
            height: 100%x;
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: flex-start;
            margin: 40px 0;
        }

        .About .aboutInfos .TextAboutInfo {
            width: 95%;
            height: 100%;
            padding: 20px 20px;
        }


        .About .topImageAbout .back {
            padding: 50px;
        }
        @media (max-width: 750px) {





        @media (max-width: 650px) {

            .About .topImageAbout {
                height: 350px;
            }
            .About .topImageAbout .back {
                padding: 50px;
            }
            
            .About .topImageAbout .back h1 {
                font-size: 32px;
            }
            .About .topImageAbout .back h3 {

                font-size: 18px;
            }

                @media (max-width: 480px) {
                    .About .topImageAbout .back {
                        padding: 40px 10px;
                    }
                    @media (max-width: 390px) {
                        .About .aboutInfos .ImagesAbout {
                            width: 90%;
                        }
                        .About .aboutInfos .ImagesAbout .imageAbout1 {
                            width: 100%;
                           
                        }
                        .About .aboutInfos .ImagesAbout .imageAbout1 img {
                            width: 100%;
                          
                        }
                        .About .aboutInfos .ImagesAbout .imageAbout2 {
                            width: 100%;
                           
                        }
                        .About .aboutInfos .ImagesAbout .imageAbout2 img {
                            width: 100%;
                           
                        }
                    }


                        
                }
            }   
        }
    }
    }
    }

    
