
.SearchPropertyHomeCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0px 0px 20px 0;
}
.SearchPropertyHomeCenter .viewFilter {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.SearchPropertyHomeCenter .search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 95%;
    border-radius: 30px;
    border: 1px solid var(--Border);
    background-color: var(--White);
    margin-top: 10px;
    padding: 7px;
}
.SearchPropertyHomeCenter .search h3 {
    display: none;
}
.SearchPropertyHomeCenter .search .primary {
    width:50%;
    border-radius: 25px 0 0 25px;
}
.SearchPropertyHomeCenter .search .address {
    width:100%;
}
.SearchPropertyHomeCenter .search input {
    width: 100%;
    background-color: var(--White);
    color: var(--Paragraph);
    padding: 10px;
    font-weight: 700;
    border:none;

    height: 40px;
}
.SearchPropertyHomeCenter .search select {
    width:50%;
    background-color: var(--White);
    color: var(--Paragraph);
    padding: 10px;
    font-weight: 700;
    border:none;

    height: 40px;
}

.SearchPropertyHomeCenter .search input::placeholder {
    color: var(--Gray);
    font-weight: 700;
}
.SearchPropertyHomeCenter .search datalist {
    position: absolute;
    background-color: white;
    border: 1px solid blue;
    border-radius: 0 0 5px 5px;
    border-top: none;
    font-family: sans-serif;
    padding: 5px;
    max-height: 10rem;
    
  }
.SearchPropertyHomeCenter .search .filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 2px 0px 2px;
    border-radius: 6px;
    background-color: var(--White);
    color: var(--Gray);
    font-weight: 500;
    border: none;
    height: 30px;
    width: 120px;
    margin: 0px 5px;
}
.SearchPropertyHomeCenter .search .filter svg {
    margin-right: 2px;
    font-size: 12px;
}
.SearchPropertyHomeCenter .viewFilter .search .btnSearch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 50px;
    background-color: var(--White);
    color: var(--Primary);
    font-weight: 500;
    border: none;
    height: 40px;
}
.SearchPropertyHomeCenter .search .btnSearch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 20px;
    background-color: var(--Primary);
    color: var(--White);
    font-weight: 500;
    border: none;
    height: 40px;
}
.SearchPropertyHomeCenter .search .mobile {
    display: none;
    padding: 10px 20px;
    border-radius: 6px;
    background-color: var(--Whte);
    color: var(--Primary);
    font-weight: 500;
    border: none;
    height: 40px;
}

.SearchPropertyHomeCenter .selectButtonsHomeTop {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
.SearchPropertyHomeCenter .selectButtonsHomeTop button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100px;
    padding: 15px 20px;
    Background-color: var(--White);
    color: var(--Paragraph);
    font-weight: 700;
    border: none;
}
.SearchPropertyHomeCenter .selectButtonsHomeTop button:hover {
    background-color: var(--Primary);
    color: var(--White);
}
.SearchPropertyHomeCenter .selectButtonsHomeTop button svg {
   margin-right: 5px;
}
.SearchPropertyHomeCenter .selectButtonsHomeTop .btn1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 15px 20px;
    Background-color: var(--White);
    color: var(--Paragraph);
    font-weight: 700;
    border-radius: 25px 0 0 25px;
}
.SearchPropertyHomeCenter .selectButtonsHomeTop .btn {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 15px 20px;
    Background-color: var(--Primary);
    color: var(--White);
    font-weight: 700;
    border-radius: 25px 0 0 25px;
}
.SearchPropertyHomeCenter .selectButtonsHomeTop .btn2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 15px 20px;
    Background-color: var(--Primary);
    color: var(--White);
    font-weight: 700;
}
.SearchPropertyHomeCenter .selectButtonsHomeTop .btn3 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 15px 20px;
    Background-color: var(--Primary);
    color: var(--White);
    font-weight: 700;
    border-radius: 0 25px 25px 0;
}
.SearchPropertyHomeCenter .selectButtonsHomeTop .btn4 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 15px 20px;
    Background-color: var(--White);
    color: var(--Paragraph);
    font-weight: 700;
    border-radius: 0 25px 25px 0;
}
.SearchPropertyHomeCenter .textLocation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    margin: 10px 0;
    flex-wrap: wrap;
}

.SearchPropertyHomeCenter .textLocation h4 {
    margin: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--Gray);
}

.SearchPropertyHomeCenter .textLocation select {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    Background-color:var(--BorderHover);
    color: var(--Paragraph);
    font-weight: 500;
    border: none;
    margin: 5px;
    border-radius: 6px;
}

.SearchPropertyHomeCenter .textLocation button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 10px;
    Background-color:var(--Border);
    color: var(--Paragraph);
    font-weight: 500;
    border: none;
    margin: 5px 10px;
    border-radius: 6px;
}


.SearchPropertyHomeCenter .textLocation .checkDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0px 5px;
}

.SearchPropertyHomeCenter .textLocation .checkDiv h5 {
    margin: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--BorderInput);
}

.SearchPropertyHomeCenter .textLocation .checkDiv h5 svg {
    margin-right: 3px;
}




.SearchPropertyHomeCenter .icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 10px 0;
    flex-wrap: wrap;
}
.SearchPropertyHomeCenter .icons .unic {
    display: flex;
    flex-direction: row;
    align-items:  flex-start;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 20px;
    margin: 5px;
    background-color: rgba(99,99,99, 0.5);
    color: var(--White);
    cursor: pointer;
}

.SearchPropertyHomeCenter .icons .unic svg {
    margin-right: 5px;
}
.SearchPropertyHomeCenter .icons .unic:hover {
    background-color: rgba(237,50,55, 0.9);
}


@media (max-width: 1200px) {


    @media (max-width: 1020px) {

        .SearchPropertyHomeCenter {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
        
        }

    @media (max-width: 900px) {
        .SearchPropertyHomeCenter .search {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 90%;

        }

        .SearchPropertyHomeCenter .selectButtonsHomeTop {
            width: 90%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
    
        @media (max-width: 700px) {  
            
            .SearchPropertyHomeCenter {
                width: 95%;
            }            

            .SearchPropertyHomeCenter .search .filter {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                padding: 0px 2px 0px 2px;
                border-radius: 6px;
                background-color: rgb(255,255,255, 0.0);
                color: var(--BorderInput);
                font-weight: 500;
                border: none;
                height: 30px;
                width: 100%;
                margin: 5px 5px;
                font-size: 14px;
            }
            .SearchPropertyHomeCenter .search .filter svg {
                margin-right: 2px;
                font-size: 12px;
            }

            .SearchPropertyHomeCenter .search .mobile {
                display: none;
                border-radius: 50px;
            }

            .SearchPropertyHomeCenter .search .btnSearch {
                background-color: var(--White);
                color: var(--Primary);
            }



            .SearchPropertyHomeCenter {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin: 20px 0;
                z-index: 1;
                position: relative;
            }


          
            .SearchPropertyHomeCenter .search {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: 95%;
                height: 100%;
                border: none;
                background: none;
                box-shadow: none;
                margin-top: -7px;

            }

            .SearchPropertyHomeCenter .search h3 {
                display:block;
                font-weight: bold;
                color: var(--White);
                margin: 10px;
            }
            .SearchPropertyHomeCenter .search .primary {
                width: 100%;
                height: 40px;
                border: 1px solid var(--White);
                border: 1px solid var(--Border);
                width: 100%;
                border-radius: 50px;
                margin-bottom: 5px;
            }

            .SearchPropertyHomeCenter .search input {
                width: 100%;
                border: 1px solid var(--White);
                border: 1px solid var(--Border);
                border-radius: 50px;
                margin-bottom: 5px;
            }
            
            .SearchPropertyHomeCenter .search select {
                width: 100%;
                border: 1px solid var(--White);
                border: 1px solid var(--Border);
                border-radius: 50px;
                margin-bottom: 5px;
            }
            
            .SearchPropertyHomeCenter .search input::placeholder {
                font-weight: 700;
            }
            .SearchPropertyHomeCenter .search button {
                width: 100%;
                padding: 15px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 50px;
            }

            .SearchPropertyHomeCenter .selectButtonsHomeTop .btn {
                width: 100%;
            }
            .SearchPropertyHomeCenter .selectButtonsHomeTop .btn1 {
                width: 100%;
            }
            .SearchPropertyHomeCenter .selectButtonsHomeTop .btn2 {
                width: 100%;
            }
            .SearchPropertyHomeCenter .selectButtonsHomeTop .btn3 {
                width: 100%;
            }
            .SearchPropertyHomeCenter .selectButtonsHomeTop .btn4 {
                width: 100%;
            }

            .SearchPropertyHomeCenter .selectButtonsHomeTop {
                width: 95%;
            }
            .SearchPropertyHomeCenter .selectButtonsHomeTop button {
                width: 100%;
            }


            .SearchPropertyHomeCenter .icons .unic {
                font-size: 14px;
            }

            @media (max-width: 480px) {
                .SearchPropertyHomeCenter {
                    margin: 10px auto;
                    width: 95%;
                }    
                .SearchPropertyHomeCenter .search {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding: 0px;
                }
                .SearchPropertyHomeCenter .selectButtonsHomeTop {
                    width: 100%;
                    justify-content: space-between;
                }

                .SearchPropertyHomeCenter .selectButtonsHomeTop button {
                    padding: 15px 10px;
                }

                
            }
        }
    }
    }
}