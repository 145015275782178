.ProcessProperty {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 1080px;
    margin: 80px auto;
    flex-wrap: wrap;
}
.ProcessProperty .processUnicText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 200px;
    margin: 10px;
    padding: 5px;
    height: 80px;
}
.ProcessProperty .processUnic {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 200px;
    margin: 10px;
    padding: 5px;
    height: 80px;
}

.ProcessProperty .processUnicText h3 {
    font-weight: 700;
    color: var(--Paragraph);
}
.ProcessProperty .processUnic h5 {
    font-weight: 600;
    color: var(--Gray);
}
.ProcessProperty .processUnicText h6 {
    color: var(--Gray);
    margin: 5px 0;
}
.ProcessProperty .processUnic svg {
    font-size: 24px;
    color: var(--Primary);
    margin: 5px 0;
}

@media (max-width: 1095px) {
    .ProcessProperty {
        width: 90%;
        
    }
    @media (max-width: 990px) {
    .ProcessProperty {
        width: 95%;
        
    }
    @media (max-width: 935px) {
        .ProcessProperty {
            justify-content: center;
            
        }

        .ProcessProperty .processUnicText {
            width: 100%;
            margin: 5px 10px;
        }
        .ProcessProperty .processUnic {
            margin: 5px;
        }
    @media (max-width: 460px) {
        .ProcessProperty .processUnic {
            width: 80%;
            margin: 5px;
        }
        
}
}
}
}