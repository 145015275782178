.BlockHome {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 1100px;
    margin: 60px auto;
    flex-wrap: wrap;
}
.BlockHome .BlockHomeUnic {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 30%;
    margin: 10px;
    border: 1px solid var(--Border);
    border-radius: 20px;
    height: 120px;
}
.BlockHome .BlockHomeUnic .textBlockHome {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 70%;
    height: 100%;
    padding: 15px;
    color: var(--Paragraph);
}
.BlockHome .BlockHomeUnic .textBlockHome h3 {
    font-weight: 700;
}
.BlockHome .BlockHomeUnic .textBlockHome button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
    border: none;
    background-color: var(--White);
}
.BlockHome .BlockHomeUnic .textBlockHome button svg {
    margin-left: 5px;
}

.BlockHome .BlockHomeUnic .textBlockHome button:Hover {
    color: var(--Primary);
}

.BlockHome .BlockHomeUnic .imageBlockHome {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 20px 20px 0;
}
.BlockHome .BlockHomeUnic .imageBlockHome img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 20px 20px 0;
}

@media (MAX-width: 1120px) {
    .BlockHome {
        width: 100%;
    }
    @media (MAX-width: 650px) {
        .BlockHome {
            flex-direction: column;
        }

        .BlockHome .BlockHomeUnic {
            width: 95%;
        }
}
}