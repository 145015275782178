
.each-slide-effect .imageTop2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  height: 500px; /* You must set a specified height */
}

.each-slide-effect .imageTop2 .image {
  width: 100%;
  height: 500px;
  object-fit:cover ;
  
}
.each-slide-effect .imageTop2 .image img {
  width: 100%;
  height: 500px;
  object-fit:cover ;
}

.each-slide-effect .imageTop2 .blockSlider2 {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--White);
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 50px;
  padding: 20px;
  z-index: 1;
  position: absolute;
}
.each-slide-effect .imageTop2 .blockSlider2 .featured {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--Primary);
  border-radius: 20px;
  margin-top: -35px;
  padding: 10px 40px;
}

.each-slide-effect .imageTop2 .blockSlider2 .featured p {
  color: var(--White);
  font-weight: 700;
}

.each-slide-effect .imageTop2 .blockSlider2 h2 {
  color: var(--Paragraph);
  font-weight: 700;
  margin: 0px 10px 5px 10px;
}
.each-slide-effect .imageTop2 .blockSlider2 h3 {
  color: var(--Paragraph);
  font-weight: 700;
  margin: 0px 5px 5px 5px;
}
.each-slide-effect .imageTop2 .blockSlider2 a {
  text-decoration: none;
}
.each-slide-effect .imageTop2 .blockSlider2 h4 {
  color: var(--Gray);
  margin: 0 5px;
  font-weight: 700;
}
.each-slide-effect .imageTop2 .blockSlider2 h5 {
  color: var(--Gray);
  margin: 0 5px;
}

  .each-slide-effect .imageTop2 .blockSlider2 .iconsBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: 7px 5px 5px 5px; 
    padding: 0px;
    flex-wrap: wrap;

}

.each-slide-effect .imageTop2 .blockSlider2 .iconsBox .iconUnicBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    padding: 0px;
    color: var(--Gray);
}
.each-slide-effect .imageTop2 .blockSlider2 .iconsBox .iconUnicBox svg {
    font-size: 18px;
    margin-right: 5px;
    color: var(--Primary);
    margin-top: -2px;

}

.each-slide-effect .imageTop2 .blockSlider2 .iconsBox .iconUnicBox .simbolBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    text-align: center;
    margin-top: 1px;
}
.each-slide-effect .imageTop2 .blockSlider2 .iconsBox .iconUnicBox .simbolBox  p {
    color: var(--Gray);
    font-weight: 600;
    font-size: 14px;
}



.each-slide-effect .imageTop2 .blockSlider2  .pricing {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: flex-start;
  margin-top: 5px;
}

.each-slide-effect .imageTop2 .blockSlider2  .pricing h5 {
  color: var(--Gray);
  font-weight: 800;
  margin: 0 5px;
  text-align: flex-start;
}
.each-slide-effect .imageTop2 .blockSlider2  .pricing h5 span {
  font-weight: 500;
  color: var(--Gray);
}
.each-slide-effect .imageTop2 .blockSlider2  .pricing h3 {
  color: var(--Primary);
  font-weight: 400;
  margin: 2px 5px 2px 5px;
  text-align: flex-end;
      width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.each-slide-effect .imageTop2 .blockSlider2  .pricing span {
  color: var(--Primary);
  font-weight: 800;
}
.each-slide-effect .imageTop2 .blockSlider2  .pricing h4 {
  color: var(--Primary);
  font-weight: 400;
  margin: 0px 0px 0px 5px;
  text-align: flex-end;
      width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.each-slide-effect .imageTop2 .blockSlider2  .pricing h4 span {
  color: var(--Primary);
  font-weight: 800;
}

@media (max-width: 1160px) {
  .each-slide-effect .imageTop2 .blockSlider2 {
    width: 500px;
  }

  @media (max-width: 950px) {

    .each-slide-effect .imageTop2 {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
      height: 350px; /* You must set a specified height */
      margin: 0px;
    }
    
    .each-slide-effect .imageTop2 .image {
      width: 100%;
      height: 350px;
      object-fit:cover ;
      
    }
    .each-slide-effect .imageTop2 .image img {
      width: 100%;
      height: 350px;
      object-fit:cover ;
    }

    .each-slide-effect .imageTop2 .blockSlider2 {
      width: 400px;
      padding: 20px;
    }

    @media (max-width: 870px) {
      .each-slide-effect .imageTop2 .blockSlider2 {
        width: 300px;
        padding: 20px;
      }
  


@media (max-width: 650px) {

  .each-slide-effect .imageTop2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .each-slide-effect .imageTop2 .blockSlider2 {
    width: 450px;
      margin: 10px;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 10px;
  }
@media (max-width: 530px) {
  .each-slide-effect .imageTop2 .blockSlider2 {
    width: 450px;
  }
  @media (max-width: 500px) {

    .each-slide-effect .imageTop2 .blockSlider2 {
      width: 400px;
    }
      .each-slide-effect .imageTop2 .blockSlider2 .icons {
          width: 100%;
          flex-wrap: wrap;
      }
      .each-slide-effect .imageTop2 .blockSlider2 .icons .iconUnic {
          width: 80px;
      }

      @media (max-width: 430px) {
        .each-slide-effect .imageTop2 .blockSlider2 {
          width: 300px;
        }

        }
  }
}
}
}
}
}
