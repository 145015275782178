.NewAlertClient {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    border-radius: 25px;
    padding: 15px 20px;
    border: none;
    color: var(--White);
    background-color: var(--ButtonDisabled);
}

.NewAlertClient svg {
    margin-right: 5px;
    font-size: 16px;
    transform: rotate(-25deg);
}

.itensModalMessage h6 {
    font-weight: 700;
    color: var(--Gray);
    margin-top: 5px;
}

.itensModalMessage h3 {
    font-weight: 700;
    color: var(--Paragraph);
    margin-bottom: 20px;
}
.itensModalMessage .btnCLient {
    width: 50%;
    border-radius: 25px;
}
