.TopHeadHome {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh; 
 /* Resize the background image to cover the entire container */
  }
  .TopHeadHome .blockTop{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%; 
    background-color: var(--Primary);
    /* background-color: rgba(255,255,255,0.9); */
    backdrop-filter: blur(5px);
    padding: 0;
  }
.TopHeadHome .blockTop .SearchText{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    height: 100%; 
    padding-top: 20px;
  }

  .TopHeadHome .blockTop .SearchText .image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
  }

  .TopHeadHome .blockTop .SearchText .image img {
    width: 80%;
    margin: 20px 0;
  }

.TopHeadHome .blockTop .SearchText .TextTopSearch{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0 20px 0;

  }



  /* .TopHeadHome .blockTop .SearchText .TextTopSearch h1 {
    font-weight: 800;
    color: var(--White);
    font-size: 45px;
    line-height: 2.8rem;
    margin-bottom:10px;
  } */

  .TopHeadHome .blockTop .SearchText .TextTopSearch h1 {
    font-weight: 900;
    font-size: 25px;
    line-height: 2.0rem;
    margin-bottom:10px;
    text-align: center;
    color: var(--White);
    margin:10px 20px;
  }

  .TopHeadHome .blockTop .SearchText .TextTopSearch h1 span {
    color: var(--White);
    text-align: center;
  }

  .TopHeadHome .blockTop .SearchText .TextTopSearch h4 {
    font-weight: 500;
    color: var(--White);
    text-align: center;
  }

  .TopHeadHome .blockTop .SearchText .TextTopSearch button {
    border: none;
    border-radius: 6px;
    background-color: var(--White);
    color: var(--White);
    padding: 10px 20px;
    margin-top: 20px;
  }

  .TopHeadHome .blockTop .SearchText .TextTopSearch .textServices{
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 30px auto 20px auto;
    flex-wrap: wrap;
  }

  .TopHeadHome .blockTop .SearchText .TextTopSearch .textServices h3{
    font-weight: 900;
    color: var(--White);
    margin: 10px;
  }


.TopHeadHome .blockTop .ImagesTop{
    width: 65%;
    border-radius: 0 0 0 100px;
    padding: 0px;
    object-fit: cover;

  }
.TopHeadHome .blockTop .ImagesTop .topData{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  padding: 0px 20px;
  }
.TopHeadHome .blockTop .ImagesTop .topData .links{
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  }

  .TopHeadHome .blockTop .ImagesTop .topData .links h5{
    font-size: 14px;
    color: var(--White);
    font-weight: 600;
    margin: 0px 10px;
    }

    .TopHeadHome .blockTop .ImagesTop .topData .links h5 a {
      text-decoration: none;
      color: var(--White);
    }
    .TopHeadHome .blockTop .ImagesTop .topData .links h5 a:hover {
      text-decoration: none;
      color: var(--White);
    }

  .TopHeadHome .blockTop .ImagesTop .topData .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    font-weight:600;
    color: var(--Text2);
    text-decoration: none;
    list-style: none;
    width: 40%;
  }

  .TopHeadHome .blockTop .ImagesTop .topData .buttons .iconUnicAdm {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    background-color: var(--White);
    font-weight:600;
    color: var(--Primary);
    font-size:14px;
    margin:5px 5px;
    box-shadow: 1px 1px 1px 0px rgba(0,0,0,0.3);
-webkit-box-shadow: 1px 1px 1px 0px rgba(0,0,0,0.3);
-moz-box-shadow: 1px 1px 1px 0px rgba(0,0,0,0.3);
}
  .TopHeadHome .blockTop .ImagesTop .topData .buttons .iconUnicAdm2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    background-color: var(--White);
    font-weight:600;
    color: var(--Primary);
    font-size:14px;
    margin:5px 5px;
    box-shadow: 1px 1px 1px 0px rgba(0,0,0,0.3);
-webkit-box-shadow: 1px 1px 1px 0px rgba(0,0,0,0.3);
-moz-box-shadow: 1px 1px 1px 0px rgba(0,0,0,0.3);
}

  /* .TopHeadHome .blockTop .ImagesTop .topData .buttons .iconUnicAdm img {
 height: 15px;
 margin-right: 5px;
}

  .TopHeadHome .blockTop .ImagesTop .topData .buttons .iconUnicAdm2 img {
 height: 15px;
 margin-right: 5px;
} */

  /* .TopHeadHome .blockTop .ImagesTop .topData .buttons .iconUnicAdm:hover {
  background-color: var(--BorderInput2);
}
  .TopHeadHome .blockTop .ImagesTop .topData .buttons .iconUnicAdm2:hover {
  background-color: var(--WhiteHover);
} */
.TopHeadHome .blockTop .ImagesTop .imagemSlider{
    width: 100%;
    height: 100%;
  }
.TopHeadHome .blockTop .ImagesTop .imagemSlider img{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%; 
    object-fit: cover;
    border-radius: 0 0 0 50px;
    border-top: 3px solid rgb(199,151,55);
    border-left: 3px solid rgb(199,151,55);
    border-bottom: 3px solid rgb(199,151,55);
  }

  @media(max-width:1050px){
    .TopHeadHome .blockTop .SearchText{
      width: 350px; 
    }

    .TopHeadHome .blockTop .ImagesTop{
      width: 60%;
    }

    @media(max-width:950px) {

      .TopHeadHome {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 100%; 
      }

      .TopHeadHome .blockTop{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 100%; 

        backdrop-filter: blur(5px);
        padding: 0;
      }

      .TopHeadHome .blockTop .SearchText{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content:center;
        height: 100%; 
      }
   
      .TopHeadHome .blockTop .SearchText .image img {
        width: 250px;
        margin-top: 40px;
      }
      .TopHeadHome .blockTop .SearchText .TextTopSearch .textServices{
        width:100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 30px auto 20px auto;
        flex-wrap: wrap;
      }
    
      .TopHeadHome .blockTop .SearchText .TextTopSearch .textServices h3{
        margin: 5px;
      }
    

      .TopHeadHome .blockTop .ImagesTop{
        width: 100%;
        height: 100%; 
      }

      .TopHeadHome .blockTop .ImagesTop .imagemSlider{
        width: 100%;
        height: 100%;
      }

      .TopHeadHome .blockTop .ImagesTop .imagemSlider img{
        width: 98%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 100%; 
        object-fit: cover;
        border-radius: 10px;
        margin: 0 auto;
        border-top: 3px solid rgb(199,151,55);
        border-left: 3px solid rgb(199,151,55);
        border-right: 3px solid rgb(199,151,55);
        border-bottom: 3px solid rgb(199,151,55);
      }


      .TopHeadHome .blockTop .ImagesTop .topData{
        display: none;
        }


      
    @media(max-width:870px) {
      @media(max-width:780px) {

        .TopHeadHome .blockTop .SearchText{
          width: 80%;
          margin-left: 0px;
        }
      
        @media (max-width: 600px) {
          .TopHeadHome {
            height: 100%; 
          }
          .TopHeadHome .blockTop{
            height: 100%; 
          }
        .TopHeadHome .blockTop .SearchText{
            height: 100%; 
          }
          .TopHeadHome .blockTop .SearchText{
            width: 95%;

          }

          .TopHeadHome .blockTop .SearchText .image img {
            margin-top: 40px;
            width: 200px;
          }

          .TopHeadHome .blockTop .SearchText .TextTopSearch{
            margin: 10px 0 20px 0;
          }

          .TopHeadHome .blockTop .SearchText .TextTopSearch h1 {
            font-weight: 900;
            font-size: 26px;
            line-height: 1.6rem;
            margin-bottom:10px;
          }
        
          }
      }
  
    }
  }
  }

  /* @media (max-width: 870px){
    
    .TopHeadHome .blockTop .SearchText .TextTopSearch{
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin: 40px 0 20px;
    }
  

    .TopHeadHome .blockTop .SearchText .TextTopSearch h1 {
      font-weight: 900;
      font-size: 30px;
      line-height: 2.0rem;
      margin-bottom:10px;
    }


    .TopHeadHome .blockTop .ImagesTop{
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%; 
    }

 

@media (max-width: 750px) {
  .TopHeadHome .blockTop .ImagesTop{
    display: none;
  }
  .TopHeadHome .blockTop .SearchText{
    width: 80%;
  }

  @media (max-width: 600px) {
    .TopHeadHome .blockTop .SearchText{
      width: 100%;
    }
    }
  }
  }
   */